<template>
    <el-form ref="balanceSheetData" class="card-tail" label-position="top" v-loading="$waiting.is('loading')">
        <MissingSruAccountsInfo
            :financial-data="balanceSheetData"
            :client-id="clientId"
            :client-info="clientInfo"
            :annual-report-id="annualReportId"
            @create-balance-sheet-data="createbalanceSheetData"
            @update-balance-sheet-details="getBalanceSheetDetails"
            @update-is-displaying-accounts="updateIsDisplayingAccounts"
            :financial-page="financialPage"
        />
        <div class="flex flex-row">
            <div class="p-5 flex" style="width: 46vw">
                <ModalInspectBalanceSheet
                    ref="inspectModal"
                    :visible="modalBalanceSheetVisible"
                    :client-id="clientId"
                    :client-info="clientInfo"
                    :balance-sheet-data="balanceSheetData"
                    :table-data="tableData"
                    :pdf-data="pdfData"
                    @close="modalBalanceSheetVisible = false"
                />
                <BalanceSheetTable :table-data="tableData" :balance-sheet-data="balanceSheetData" @display-accounts="displayAccounts" />
            </div>
            <AccountsSection
                :is-displaying-accounts="isDisplayingAccounts"
                :client-id="clientId"
                :client-info="clientInfo"
                :selected-accounts="selectedAccounts"
                :balance-sheet-data="balanceSheetData"
                :titles-data="titlesData"
                :annual-report-id="annualReportId"
                @show-move-account-modal="showMoveAccountModal()"
                @update-balance-sheet-details="getBalanceSheetDetails"
                @update-is-displaying-accounts="updateIsDisplayingAccounts"
                :is-period="isPeriod"
            />
        </div>
        <ActionButtons @go-to-page-before="goToPageBefore" @show-inspect="showInspectBalanceSheet" @go-to-next-page="goToNextPage" />
    </el-form>
</template>

<script>
import Api from "../annualReport.api";

export default {
    name: "BalanceSheet",
    components: {
        ModalInspectBalanceSheet: () => import("./components/BsInspectPdf.vue"),
        BalanceSheetTable: () => import("./components/BsTable.vue"),
        AccountsSection: () => import("./components/BsAccountsSection.vue"),
        ActionButtons: () => import("../components/ActionButtons.vue"),
        MissingSruAccountsInfo: () => import("../incomeStatement/components/MissingSruAccountsInfo.vue"),
    },
    props: {
        annualReportId: {
            type: String,
            required: true,
        },
        clientInfo: {
            type: Object,
        },
    },
    data() {
        return {
            clientId: null,
            balanceSheetData: {},
            modalBalanceSheetVisible: false,
            isDisplayingAccounts: false,
            tableData: [],
            selectedAccounts: [],
            titlesData: [],
            isPeriod: false,
            isResetModalVisible: false,
            financialPage: "balansräkningen",
            pdfData: [],
        };
    },
    async created() {
        this.clientId = this.$store.state.topBar.selectedClient.value;
        await this.getBalanceSheetDetails();
    },
    methods: {
        async getBalanceSheetDetails() {
            this.$waiting.start("loading");
            let data = await Api.getBalanceSheetDetails(this.annualReportId);
            if (Object.keys(data).length > 0) {
                this.balanceSheetData = data;
            }
            await this.createBalanceSheetDetails(data);
            await this.getPdfData(data);
            this.tableData = this.filterData(this.balanceSheetData, row => [1, 5, 3].includes(row.type) && this.totalIsNotEmpty(row) && (row.previousPeriodRounded !== 0 || row.periodRounded !== 0));
            this.titlesData = this.titleAccounts(5);
            this.$waiting.end("loading");
        },
        async getPdfData(data) {
            this.pdfData = this.filterData(data, row => [1, 4, 5, 3].includes(row.type) && this.totalIsNotEmpty(row) && (row.previousPeriodRounded !== 0 || row.periodRounded !== 0));
        },
        async createBalanceSheetDetails(data) {
            let newData = await Api.createBalanceSheet(this.annualReportId, this.clientId, this.clientInfo.financialYearStart, this.clientInfo.financialYearEnd, false);
            if (Object.keys(newData).length > 0) {
                this.balanceSheetData = newData;
                if (Object.keys(data).length > 0) {
                    this.$notify.info({ title: "Ändringar i bokföringen har skett, balansräkningen är återställd" });
                }
            }
        },
        filterData(data, condition) {
            if (data && data.row) {
                return data.row.filter(condition);
            }
            return [];
        },
        displayAccounts(row, isPeriod) {
            this.$waiting.start("loading");
            this.isPeriod = isPeriod;
            if (!this.isNameUppercase(row)) {
                if (this.isDisplayingAccounts === false) {
                    this.isDisplayingAccounts = true;
                }
                this.selectedAccounts = this.filteredAccounts(row.sruCode);
            }
            this.$waiting.end("loading");
        },
        goToNextPage() {
            this.$router.push(`/annualReport/accountingPrinciples/${this.annualReportId}`);
        },
        goToPageBefore() {
            this.$router.push(`/annualReport/incomeStatement/${this.annualReportId}`);
        },
        showInspectBalanceSheet() {
            this.modalBalanceSheetVisible = true;
            this.$nextTick(() => {
                this.$refs.inspectModal.$el.focus();
            });
        },
        updateIsDisplayingAccounts(newValue) {
            this.isDisplayingAccounts = newValue;
        },
        async createbalanceSheetData(newData) {
            this.balanceSheetData = newData;
        },
        totalIsNotEmpty(row) {
            if (row.type === 3) {
                const rowIndex = this.balanceSheetData.row.findIndex(r => r === row);
                if (rowIndex > 0) {
                    const previousRow = this.balanceSheetData.row[rowIndex - 1];
                    const isNotEmpty = !!previousRow.previousPeriodRounded || !!previousRow.periodRounded;
                    return isNotEmpty;
                }
                return false;
            }
            const sumName = this.balanceSheetData.row.find(r => r.name === "SUMMA " + row.name);
            return (sumName && sumName.previousPeriodRounded !== 0 && sumName.periodRounded !== 0) || (row.previousPeriodRounded !== null && row.periodRounded !== null);
        },
        showAccountsFor(row) {
            if (row.type === 4) {
                return row.type === 4 && row.originalName === "Bundet eget kapital" && row.originalName === "Fritt eget kapital";
            }
            return;
        },
    },
    computed: {
        isNameUppercase() {
            return row => {
                if (row && row.name && typeof row.name === "string") {
                    return row.name === row.name.toUpperCase();
                } else {
                    return false;
                }
            };
        },
        filteredAccounts() {
            return sruCode => {
                return this.balanceSheetData.row.filter(row => row.sruCode === sruCode);
            };
        },
        titleAccounts() {
            return type => {
                return this.balanceSheetData.row.filter(row => row.type === type && row.name != row.name.toUpperCase());
            };
        },
    },
};
</script>
